import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import theme from 'styles/theme'
import { heading } from 'styles/typography'

type ButtonProps = {
  inverted?: boolean
}

const Button = styled.button<ButtonProps>`
  border: none;
  border-radius: 5rem;
  background: ${theme.color.accent.primary};
  color: ${theme.color.text.light};
  padding: 2rem 2.4rem;
  white-space: nowrap;
  appearance: none;
  transition: color 0.4s, background-color 0.4s, box-shadow 0.4s;
  box-shadow: inset 0 0 0 ${theme.color.background.dark}00;
  margin: 0;
  ${heading.xs};

  &:not([disabled]) {
    cursor: pointer;

    &:hover,
    &:focus {
      box-shadow: inset 0 0 0 2em ${theme.color.background.dark};
    }
  }

  &[disabled] {
    opacity: 0.3;
  }

  ${props =>
    props.inverted &&
    css`
      box-shadow: inset 0 0 0 rgba(255, 255, 255, 0);

      &:not([disabled]) {
        &:hover,
        &:focus {
          color: ${theme.color.background.dark};
          box-shadow: inset 0 0 0 2em white;
        }
      }
    `}
`

export const OutlineButton = styled(Button as any)`
  box-shadow: 0 0 0 1.5px ${theme.color.text.dark} inset;
  background: none;
  color: ${theme.color.text.dark};

  &:not([disabled]) {
    &:hover,
    &:focus {
      color: ${theme.color.text.light};
      background-color: ${theme.color.accent.primary};
      box-shadow: inset 0 0 0 2em ${theme.color.accent.primary};
    }
  }
`

export const ButtonLink = styled(Button as any).attrs({ as: Link })<
  ButtonProps
>`
  text-decoration: none;
`

export const OutlineButtonLink = styled(OutlineButton as any).attrs({
  as: Link,
})`
  text-decoration: none;
`

export default Button
